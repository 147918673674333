import React from 'react';

import Page from 'components/Page';
import RelatedProjects from '../RelatedProjects';

export default ({ children, omitProjects = [], ...rest }) => (
  <Page {...rest} mt={5}>
    {children}
    <RelatedProjects omitProjects={omitProjects} />
  </Page>
);
