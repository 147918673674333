import React from 'react';
import styled from '@emotion/styled';

import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { H3, TextLight, Paragraph } from 'components/Typo';
import CenterContent from 'components/CenterContent';

const ProjectHeadline = ({ children }) => {
  return (
    <Paragraph mb={3}>
      <H3 children={children} />
    </Paragraph>
  );
};

const ProjectParagraph = ({ children }) => {
  return (
    <Paragraph mb={3}>
      <TextLight children={children} />
    </Paragraph>
  );
};

const CustomComponents = {
  h1: ProjectHeadline,
  h2: ProjectHeadline,
  h3: ProjectHeadline,
  h4: ProjectHeadline,
  p: ProjectParagraph,
};

const ProjectWrapper = styled(CenterContent)`
  .gatsby-resp-image-wrapper {
    margin-top: ${(p) => p.theme.spacePx[5]};
    margin-bottom: ${(p) => p.theme.spacePx[5]};
  }

  .gatsby-resp-image-figure {
    margin: 0;

    .gatsby-resp-image-wrapper {
      margin-bottom: 0;
    }

    .gatsby-resp-image-figcaption {
      text-align: center;
      margin-top: ${(p) => p.theme.spacePx[2]};
      margin-bottom: ${(p) => p.theme.spacePx[5]};
    }
  }
`;

const ProjectMdx = ({ content = null }) => {
  return (
    <MDXProvider components={CustomComponents}>
      <ProjectWrapper>
        <MDXRenderer>{content}</MDXRenderer>
      </ProjectWrapper>
    </MDXProvider>
  );
};

export default ProjectMdx;
