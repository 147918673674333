import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Box } from 'components/ReflexBox';

import ProjectPage from 'components/Page/Project';
import CenterContent from 'components/CenterContent';
import ProjectIntro from 'components/ProjectIntro';
import TechnologyStack from 'components/TechnologyStack';
import Button from 'components/Button';
import CollaborationButton from 'components/CollaborationButton';

import MdxRenderer from './mdx-renderer/ProjectMdx';

const ContactWrapper = styled(Box)`
  background-color: #1e1e32;
`;

const ProjectTemplate = ({ data }) => {
  const {
    title,
    createdAt,
    client,
    url,
    description,
    imageTeaserWide,
    team,
    tasks,
    technologies,
  } = data.project.frontmatter;

  const metaTags = {
    title: `webkid - project - ${title}`,
    author: 'Webkid',
    twitterUsername: '@webk1d',
    description: description,
    siteUrl: `https://webkid.io${data.project.fields.slug}`,
    robots: 'index,follow',
    image: `https://webkid.io${imageTeaserWide.src}`,
  };

  return (
    <ProjectPage metaTags={metaTags} omitProjects={[data.project.fields.slug]}>
      {imageTeaserWide && (
        <CenterContent big>
          <GatsbyImage
            image={imageTeaserWide.childImageSharp.gatsbyImageData}
          />
        </CenterContent>
      )}
      <CenterContent>
        <ProjectIntro
          title={title}
          description={description}
          client={client}
          tasks={tasks}
          team={team}
          published={createdAt}
          links={[{ text: 'Live Project', href: url }]}
        />
      </CenterContent>
      <MdxRenderer content={data.project.body} />
      <CenterContent>
        <Box maxWidth={520} my={5} mx="auto">
          <Button
            style={{ display: 'inline-block', padding: '14px 16px 14px 16px' }}
            as="a"
            href={url}
          >
            Visit Live Project
          </Button>
        </Box>
        <Box maxWidth={520} my={5} mx="auto">
          <TechnologyStack items={technologies} />
        </Box>
      </CenterContent>
      <ContactWrapper>
        <CenterContent>
          <CollaborationButton my={0} py={[4, 5, 6, 7]} />
        </CenterContent>
      </ContactWrapper>
    </ProjectPage>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    project: mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        client
        createdAt
        title
        url
        description
        team
        tasks
        technologies
        imageTeaserWide {
          childImageSharp {
            gatsbyImageData(width: 1800)
          }
        }
      }
    }
  }
`;
