import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { filterPublished } from 'utils/project-utils';

export default function useFeaturedProjects(maxLength = 0) {
  const rawData = useStaticQuery(graphql`
    query ProjectFeaturedQuery {
      allMdx(
        filter: { fields: { slug: { regex: "/projects/" } } }
        sort: { fields: [frontmatter___homeOrder], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              createdAt
              url
              client
              type
              isDark
              description
              homeOrder
              published
              imageTeaserText {
                childImageSharp {
                  gatsbyImageData(width: 580)
                }
              }
            }
          }
        }
      }
    }
  `);

  const data = useMemo(() => {
    const projects = rawData.allMdx.edges
      .map(({ node }) => ({
        id: node.fields.slug,
        ...node.fields,
        ...node.frontmatter,
      }))
      .filter(filterPublished)
      .sort((a, b) => a.homeOrder - b.homeOrder);

    return maxLength ? projects.slice(0, maxLength) : projects;
  }, [rawData, maxLength]);

  return data;
}
