import React, { useRef } from 'react';
import { Box, Flex } from 'components/ReflexBox';
import { Link } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

import CenterContent from 'components/CenterContent';
import CardSlider from 'components/CardSlider';
import { H2 } from 'components/Typo';
import Button from 'components/Button';
import ProjectTeaser from 'components/ProjectTeaser/Small';
import useSliderOffset from 'hooks/useSliderOffset';
import useFeaturedProjects from 'hooks/useFeaturedProjects';

import { breakpoints } from 'utils/css-utils';
import { shuffleProjects } from 'utils/project-utils';

const slidesSpaceBetween = 16;

const getSlidesPerView = () => {
  const width = typeof window !== 'undefined' ? window.innerWidth : 0;

  if (width < breakpoints.m) {
    return 1.2;
  } else if (width < breakpoints.l) {
    return 1.8;
  } else if (width < breakpoints.xl) {
    return 2.4;
  }

  return width / 600;
};

export default ({ omitProjects = [] }) => {
  const domNode = useRef();
  const slidesPerView = getSlidesPerView();

  const sliderOffset = useSliderOffset({
    domNode,
    slidesPerView,
    spaceBetween: slidesSpaceBetween,
  });
  const featuredProjects = useFeaturedProjects();

  const projects = featuredProjects
    .filter((project) => !omitProjects.some((op) => op === project.slug))
    .map((project) => ({
      id: project.id,
      slug: project.slug,
      kicker: project.type,
      title: project.title,
      isDark: project.isDark,
      text: project.description,
      imgSrc: getSrc(project.imageTeaserText?.childImageSharp?.gatsbyImageData),
    }));

  const sliderItems = shuffleProjects(projects);

  return (
    <Box pt={3} pb={5} mt={0} bg="sectionBackground">
      <CenterContent ref={domNode}>
        <H2 as="div">Featured Projects</H2>
      </CenterContent>
      {sliderOffset !== null && (
        <CardSlider
          slidesPerView={slidesPerView}
          slidesOffsetBefore={sliderOffset}
          spaceBetween={slidesSpaceBetween}
          centeredSlides={true}
          items={sliderItems}
          SlideItem={ProjectTeaser}
        />
      )}
      <Flex mt={4} justifyContent="center" alignItems="center">
        <Button as={Link} to="/portfolio" icon="lightning">
          Show all projects
        </Button>
      </Flex>
    </Box>
  );
};
